<template>
  <div class="py-50">
    <div v-for="task in taskList" :key="task.id" class="mb-0">
      <b-card-actions
        v-if="task.status"
        :title="`Task ID: ${task.id}`"
        action-close
        @close="stopWatchingTaskStatus(task.id)"
      >
        <div v-if="task.status === 'SUCCESS'" class="w-100">
          <div class="d-inline-flex w-100 justify-content-start mb-0">
            <feather-icon
              icon="CheckIcon"
              class="text-success font-large-2 mx-2 mt-50"
            />
            <div class="mt-50">
              <h4 class="text-success mb-0">Import complete</h4>
              <p>
                The model snapshot has finished importing, you should now be able to open it from the
                <span class="text-primary font-weight-bold">Snapshots</span> tab below.
              </p>
            </div>
          </div>
        </div>
        <div v-if="task.status === 'ERROR'" class="w-100">
          <div class="d-inline-flex w-100 justify-content-start mb-0">
            <feather-icon
              icon="AlertCircleIcon"
              class="text-danger font-large-2 mx-2 mt-50"
            />
            <div class="mt-50">
              <h4 class="text-danger mb-0">Import error</h4>
              <p class="mb-0">
                An error occurred while importing the snapshot, we have reverted any changes made during this process.
              </p>
              <p>Please check your data and try again, if the errors persist, you will need to contact an administrator.</p>
              <pre>{{ task }}</pre>
            </div>
          </div>
        </div>
        <div v-if="task.status === 'PROGRESS'" class="w-100">
          <h5 class="text-primary font-weight-bold mb-50">
            {{ task.info.msg }}
          </h5>
          <b-progress
            v-if="task.info.total"
            :value="task.info.created"
            :max="task.info.total"
            class="bg-facebook"
            style="min-height: 1.5rem;"
            show-value
            striped
            animated
          />
          <hollow-dots-spinner
            v-else
            style="min-height: 1.5rem;"
            class="align-content-center"
          />
        </div>
      </b-card-actions>
    </div>

    <b-tabs id="menu" class="mb-2">
      <b-tab title="Models">
        <h5 class="ml-1 mt-2">
          My Models
        </h5>
        <hr>
        <model-table v-if="userModels.length > 0" :table-data="userModels" />
        <div v-else class="mb-4 ml-1">
          <span class="text-muted">No models owned by the current user.</span>
        </div>

        <h5 class="ml-1">
          Shared Models
        </h5>
        <hr>
        <model-table v-if="sharedModels.length > 0" :table-data="sharedModels" />
        <div v-else class="mb-4 ml-1">
          <span class="text-muted">No models shared with the current user.</span>
        </div>

        <h5 class="ml-1">
          Public Models
        </h5>
        <hr>
        <model-table v-if="publicModels.length > 0" :table-data="publicModels" />
        <div v-else class="mb-4 ml-1">
          <span class="text-muted">No public models found.</span>
        </div>
      </b-tab>

      <!-- Model Snapshots Tab -->
      <b-tab title="Snapshots">
        <h5 class="ml-1 mt-2">
          My Snapshots
        </h5>
        <hr>
        <temp-model-table v-if="userSnapshots.length > 0" id="model-snapshots-table" :table-data="userSnapshots" />
        <div v-else class="mb-4 ml-1">
          <span class="text-muted">No snapshots owned by the current user.</span>
        </div>

        <h5 class="ml-1">
          Shared Snapshots
        </h5>
        <hr>
        <temp-model-table v-if="sharedSnapshots.length > 0" :table-data="sharedSnapshots" />
        <div v-else class="mb-4 ml-1">
          <span class="text-muted">No snapshots shared with the current user.</span>
        </div>

        <h5 class="ml-1">
          Public Snapshots
        </h5>
        <hr>
        <temp-model-table v-if="publicSnapshots.length > 0" :table-data="publicSnapshots" />
        <div v-else class="mb-4 ml-1">
          <span class="text-muted">No public snapshots found.</span>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import HollowDotsSpinner from '@/components/Spinners/HollowDotsSpinner.vue'
import coreService from '@/libs/api-services/core-service'
import axiosIns from '@/libs/axios'
import store from '@/store'
import ModelTable from '@/views/Home/ModelTable.vue'
import TempModelTable from '@/views/Home/TempModelTable.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { computed, ref } from '@vue/composition-api'

export default {
  name: 'ModelsBrowser',
  components: {
    BCardActions,
    HollowDotsSpinner,
    ModelTable,
    TempModelTable,
  },
  setup(props, context) {
    const userModels = ref([])
    const getUserModels = () => {
      axiosIns
        .get('/api/v2/models/user')
        .then(({ data }) => { userModels.value = data })
        .catch(e => console.error(`[ModelsBrowser::getUserModels] ${e}`))
    }

    const sharedModels = ref([])
    const getSharedModels = () => {
      axiosIns
        .get('/api/v2/models/shared')
        .then(({ data }) => { sharedModels.value = data })
        .catch(e => console.error(`[ModelsBrowser::getSharedModels] ${e}`))
    }

    const publicModels = ref([])
    const getPublicModels = () => {
      axiosIns
        .get('/api/v2/models/public')
        .then(({ data }) => { publicModels.value = data })
        .catch(e => console.error(`[ModelsBrowser::getPublicModels] ${e}`))
    }

    const publicSnapshots = ref([])
    const sharedSnapshots = ref([])
    const userSnapshots = ref([])
    const fetchSnapshots = () => {
      coreService
        .get('/v1/model/temp')
        .then(({ data }) => {
          publicSnapshots.value = data.public_models
          sharedSnapshots.value = data.shared_models
          userSnapshots.value = data.user_models
        })
        .catch(e => console.error(e))
    }

    const getAllModels = () => {
      getUserModels()
      getSharedModels()
      getPublicModels()
      fetchSnapshots()
    }
    getAllModels()

    const taskIdList = computed(() => store.state.workerTasks.taskList)
    const taskList = ref([])
    const getTaskStatus = taskId => {
      coreService.get(`/v1/tasks/${taskId}/status`)
        .then(({ data }) => {
          taskList.value = { ...taskList.value, [taskId]: data }
          if (['PROGRESS', 'PENDING'].includes(data.status)) {
            setTimeout(() => getTaskStatus(taskId), 500)
          } else if (data.status === 'SUCCESS') {
            getAllModels()
          } else {
            context.root.$toast({
              component: ToastificationContent,
              props: {
                title: `An error occurred on task ${taskId}`,
                text: data.result,
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(e => console.error(e))
        .finally(() => { store.state.model.snapshot_task_id = null })
    }
    const pollTaskStatuses = () => {
      taskIdList.value.forEach(taskId => {
        getTaskStatus(taskId)
      })
    }
    pollTaskStatuses()
    const stopWatchingTaskStatus = taskId => {
      store.commit('workerTasks/REMOVE_TASK_FROM_LIST', taskId)
    }

    return {
      userModels,
      sharedModels,
      publicModels,
      publicSnapshots,
      sharedSnapshots,
      userSnapshots,
      taskList,
      taskIdList,
      stopWatchingTaskStatus,
    }
  },
}
</script>
